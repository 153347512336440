const $ = require("jquery");
const AS_NONE = 'NONE';
const AS_ERROR = 'ERROR';
const AS_TRANSITION = 'TRANSITION';
const AS_LISTEN_CHANNEL = 'LISTEN_CHANNEL';
const AS_LISTEN_SHOW = 'LISTEN_SHOW';

const PLAYER_FADE_TIME = 1000;
const PLAYER_ERROR_RETRY = 3000;
const PLAYER_ERROR_RETRY_COUNT = 20;

class SPA {

    volume = 0.8;
    volumeSlider = null;

    seekInProgress = false;

    currentState = AS_NONE;
    currentData = null;
    currentPlayer = null;

    isInTransition = false;
    targetState = AS_NONE;
    targetData = null;
    targetPlayer = null;

    loadErrorsInARow = 0;



    constructor() {
        var that = this;

        this.socket = io('/', { path: '/socket.chat' });
        this.socket.on('TRACKUPDATE', function(data) {
            $('.'+data.channel+'Title').text(data.track.metatitle);
            $('.'+data.channel+'Artist').text(data.track.metaartist);
            $('.'+data.channel+'Image').attr('src', data.track.image);
            $('.'+data.channel+'Image').attr('alt', data.track.metaartist);

            if (that.currentState == 'LISTEN_CHANNEL' && that.currentData.channel == data.channel) {
                that.currentData.artist = data.track.metaartist;
                that.currentData.title = data.track.metatitle;
                that.currentData.image = data.track.image;
                that.currentData.id = data.track.id;
                that._updatePlayerDisplay();
            }
        });

        this.currentPlayer = new Howl({
            src: ['/unlock.mp3'],
            html5: true
        });
        this.volumeSlider = $('#jsPlayer input.slider').slider({
            min: 0,
            max: 1,
            step: 0.01,
            value: this.volume,
            tooltip: 'hide'
        }).on('slide', function(e) {
            that.volume = e.value;
            if (that.currentPlayer) {
                that.currentPlayer.volume(e.value);
            }
        });

        window.addEventListener('popstate', function (event) {
            that.ajaxLoadPage({url: document.location}, false);
        });
    }

    ajaxLoadPage(ajaxOpts, pushHistory) {
        $.ajax(ajaxOpts).done(function( data ) {
            $('title').replaceWith('<title>'+data.title+'</title>');
            $('#ajaxContent').replaceWith('<div id="ajaxContent">'+data.content+'</div>');
            $('#pageScript').replaceWith('<script id="pageScript" type="application/javascript">'+data.script+'</script>');
            if (pushHistory) history.pushState({}, data.title, data.url);
            _internalPageReady();
        });
    }

    connectActions(selector) {
        var that = this;
        var progress = $(selector+' .jsShowProgress');
        progress.unbind().mousemove(function(e) {
            var w = e.target.clientWidth;
            var x = e.offsetX;
            var progress = Math.floor( (x / w) * 100);
            that._setTrackProgress(progress);
        }).mouseup(function(e) {
            var w = e.target.clientWidth;
            var x = e.offsetX;
            var progress = Math.floor( (x / w) * 100);
            that._setTrackProgress(progress);
            if (that.currentPlayer && that.currentPlayer.playing()) {
                that.currentPlayer.seek( Math.floor(that.currentPlayer.duration() * (x / w)) );
            }
        }).mouseenter(function() {
            that.seekInProgress = true;
        }).mouseleave(function() {
            that.seekInProgress = false;
            requestAnimationFrame(that._setProgressStep.bind(that));
        });
        var cast = $(selector+' .jsTrackCast');
        cast.unbind().mouseup(function(e) {
            alert('start cast process');
        });

        var like = $(selector+' .jsTrackLike');
        like.unbind().mouseup(function(e) {
            alert('start like process');
        });

        $(selector+' a').not('.dropdown-toggle').unbind('click').click(function(e) {
            var a = $(this);
            if (a.attr('target') != '_blank' && a.attr('data-bs-toggle') == null) {
                if (a.hasClass('jsPlayerControl') && !that.isInTransition && that.currentState != AS_NONE) {
                    e.preventDefault();
                    if (!that.isInTransition && (that.currentState == AS_LISTEN_SHOW ||  that.currentState == AS_LISTEN_CHANNEL)) {
                        that.transitionTo(AS_NONE, null);
                    }
                } else if (a.attr('data-track')) { // track selector
                    e.preventDefault();
                    that.tuneTo(a.attr('data-track'));
                } else if (!a.attr('href').startsWith('http')) { // spa page switch
                    e.preventDefault();
                    that.ajaxLoadPage({
                        url: a.attr('href'),
                    }, true);
                } // else: normal page switch
            }
        })
        $(selector+' form').not('.noajax').submit(function(e) {
            e.preventDefault();
            var form = $(this);
            // TODO: show loading indicator
            var action = form.attr('action');
            if (!action || action.length == 0) {
                action = window.location.pathname;
            }
            that.ajaxLoadPage({
                url: action,
                method: form.attr('method'),
                data: form.serialize()
            }, true);
        });

        $('input.tags').each(function(i,o) {
            if ( $(o).not('.tagConnected') ) {
                new Tagify(o);
                $(o).addClass('tagConnected');
            }
        });
    }

    tuneTo(target) {
        var that = this;

        $('#jsPlayer').removeClass('d-none');

        var targetData = target.split(':');
        if (targetData && targetData.length >= 2) {
            var type = targetData[0];
            var subType = targetData[1];
            console.log('tuneTo: '+type+' subType: '+subType+' channeldata: ',window.channels);
            if (type == 'channel') {
                var tuneData = window.channels[subType];
                var urls = tuneData.url;
                if ('https' == window.location.protocol || 'https:' == window.location.protocol) {
                    urls = tuneData.urlSecure;
                }
                var url = '/channel/tunein/'+subType;
                $.ajax({
                    url: url
                }).done(function( data ) {
                    if (data.valid && data.valid == '1') {
                        that.transitionTo(AS_LISTEN_CHANNEL, {
                            channel: subType,
                            channelName: data.name,
                            format: tuneData.format,
                            urls: urls,
                            artist: data.metaartist,
                            title: data.metatitle,
                            image: data.image,
                            id: data.id
                        });
                    }
                });
            } else if (type == 'show-episode' && targetData.length == 3) {
                var id =  targetData[2];
                var url = '/show/'+subType+'/'+id;
                $.ajax({
                    url: url
                }).done(function( data ) {
                    if (data.valid && data.valid == '1') {
                        data.artist = false;
                        that.transitionTo(AS_LISTEN_SHOW, data);
                    }
                });
            } else {
                console.log('ERROR: unhandled tuneTo!');
            }
        }
    }

    transitionTo(target, data) {
        if (this.isInTransition || this.currentState == AS_TRANSITION) { // cancel if already in transition
            console.log('cancel: in transition!');
            return;
        }
        if (target == this.currentState && (
            (data == null) ||
            (data.channel && data.channel == this.currentData.channel) ||
            (data.show && data.show == this.currentData.show)
            )
        ) { // cancel if same target
            console.log('cancel: target already reached!');
            return;
        }
        console.log('transitionTo ',target,' > ',data);
        this.isInTransition = true;
        this.targetState = target;
        this.targetData = data;
        this._doTransition();
    }

    _updatePlayerDisplay() {
        var player = $('#jsPlayer');

        var data = this.currentData ? this.currentData : this.targetData;
        if (data) {
            console.log('_updatePlayerDisplay() data', data);
            if (data.url) {
                player.find('.jsSoundcloud').removeClass('d-none');
                player.find('.jsSoundcloud').attr('href', data.url);
            } else {
                player.find('.jsSoundcloud').addClass('d-none');
            }

            player.find('.jsCover').attr('src', data.image);
            player.find('.jsSubType').text(data.channelName);
            if (data.artist) {
                player.find('.jsTitle').text(data.title + ' by ' + data.artist.toUpperCase());
            } else {
                player.find('.jsTitle').text(data.title);
            }

            if ('mediaSession' in navigator) {
                navigator.mediaSession.metadata = new MediaMetadata({
                    title: data.title,
                    artist: data.artist ? data.artist.toUpperCase() : '',
                    album: data.channelName,
                    artwork: [
                        { src: data.image,   sizes: '400x400',   type: 'image/jpeg' },
                    ]
                });
            }

            player.find('.jsTrackLike').attr('data-track-id', data.id);
        }

        if (this.currentState == AS_TRANSITION) {
            $('body').addClass('playing');
            player.find('.jsLoading').removeClass('d-none');
            player.find('.jsPlay').addClass('d-none');
            player.find('.jsPause').addClass('d-none');
        } else if (this.currentState == AS_NONE) {
            $('body').removeClass('playing');
            $('#jsPlayer').addClass('d-none');
            player.find('.jsLoading').addClass('d-none');
            player.find('.jsPlay').removeClass('d-none');
            player.find('.jsPause').addClass('d-none');
        } else {
            $('body').addClass('playing');
            player.find('.jsLoading').addClass('d-none');
            player.find('.jsPlay').addClass('d-none');
            player.find('.jsPause').removeClass('d-none');
        }
        if (this.currentState == AS_LISTEN_SHOW) {
            $('.jsShowProgress').removeClass('d-none');
        } else {
            $('.jsShowProgress').addClass('d-none');
        }
    }

    _setTrackProgress(progress) {
        $('.jsProgressMeter').width(progress+'%');
    }

    _setProgressStep() {
        var that = this;
        if (this.currentPlayer && this.currentPlayer.playing()) {
            var position = this.currentPlayer.seek();
            var duration = this.currentPlayer.duration();
            var formatTime = function(secs) {
                var minutes = Math.floor(secs / 60) || 0;
                var seconds = Math.floor(secs - minutes * 60) || 0;
                return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
            };
            $('#jsPlayer .jsTimeCurrent').html(formatTime(position));
            $('#jsPlayer .jsTimeTotal').html(formatTime(duration));

            if (!this.seekInProgress) {
                this._setTrackProgress((((position / duration) * 100) || 0));
            }
            requestAnimationFrame(that._setProgressStep.bind(that));
        }
    }

    _setCurrentState(state) {
        console.log('_setCurrentState ', this.currentState,' > ', state);
        this.currentState = state;
        this._updatePlayerDisplay();
    }

    _restartCurrent() {
        this.targetData = this.currentData;
        this._doTransition();
    }
    _restartTarget() {
        if (this.currentPlayer) {
            this.currentPlayer.unload();
            this.currentPlayer = null;
        }
        this._doTransition();
    }

    _cancelTransition() {
        var that = this;
        that.currentPlayer = null;
        that.currentState = AS_ERROR;
        that.isInTransition = false;
        that.transitionTo(AS_NONE, null);
        that._updatePlayerDisplay();
    }

    _doTransition() {
        var that = this;
        var from = this.currentState;
        var to = this.targetState;

        console.log('_doTransition ', from,' > ', to);
        switch (to) {
            case AS_ERROR:
                that.targetState = AS_NONE;
                that.targetData = null;
                that.targetPlayer = null;
                that.isInTransition = false;
                break;
            case AS_NONE:
                if (that.currentPlayer) {
                    that._setCurrentState(AS_TRANSITION);
                    that.volume = that.currentPlayer.volume();
                    that.currentPlayer.once('fade', function() {
                        if (that.currentPlayer) {
                            that.currentPlayer.unload();
                        }
                        that.currentPlayer = that.targetPlayer;

                        that.currentData = that.targetData;
                        that._setCurrentState(that.targetState);

                        that.targetState = AS_NONE;
                        that.targetData = null;
                        that.targetPlayer = null;

                        that.isInTransition = false;
                        that._updatePlayerDisplay();
                    })
                    that.currentPlayer.fade(that.volume, 0.0, PLAYER_FADE_TIME);
                } else {
                    that.targetState = AS_NONE;
                    that.targetData = null;
                    that.targetPlayer = null;
                    that.currentData = that.targetData;
                    that.isInTransition = false;
                    that._updatePlayerDisplay();
                }
                break;
            case AS_LISTEN_SHOW:
                that._setCurrentState(AS_TRANSITION);

                that.targetPlayer = new Howl({
                    src: that.targetData.urls,
                    format: that.targetData.format,
                    html5: true,
                    onend: function() {
                        that.loadErrorsInARow++;
                        if (that.loadErrorsInARow > 3) {
                            that._cancelTransition();
                        } else {
                            if (that.currentData && that.currentData.nextChannel) {
                                that.tuneTo('channel:'+that.currentData.nextChannel);
                            } else {
                                that.targetState = AS_LISTEN_SHOW;
                                that._restartTarget();
                            }
                        }
                    },
                    onloaderror: function() {
                        console.log('load error');
                        that.loadErrorsInARow++;
                        if (that.loadErrorsInARow > PLAYER_ERROR_RETRY_COUNT) {
                            that._cancelTransition();
                        } else {
                            window.setTimeout(function() {
                                console.log('load error retry...');
                                that._restartTarget();
                            }, PLAYER_ERROR_RETRY);
                        }
                    },
                });
                that.targetPlayer.volume(0.0);
                that.targetPlayer.once('load', function() {
                    console.log('target player: load');
                    that.loadErrorsInARow = 0;
                    if (that.currentPlayer) {
                        that.volume = that.currentPlayer.volume();
                        that.currentPlayer.fade(that.volume, 0.0, PLAYER_FADE_TIME);
                    }
                    that.targetPlayer.once('fade', function() {
                        console.log('target player: fade');
                        if (that.currentPlayer) {
                            that.currentPlayer.unload();
                        }
                        that.currentPlayer = that.targetPlayer;

                        that.currentData = that.targetData;

                        $('#jsPlayer .jsWaveform').attr('style', 'background-image: url('+that.currentData['waveform']+');');
                        $('#jsPlayer .jsCover').attr('src', that.currentData['artwork']);

                        that._setCurrentState(that.targetState);

                        that.targetData = null;
                        that.targetPlayer = null;
                        that.targetState = AS_NONE;

                        that.isInTransition = false;

                        requestAnimationFrame(that._setProgressStep.bind(that));
                    })
                    that.targetPlayer.fade(0.0, that.volume, PLAYER_FADE_TIME);
                });
                console.log('target player: play');
                that.targetPlayer.play();
                break;
            case AS_LISTEN_CHANNEL:
                that._setCurrentState(AS_TRANSITION);
                that.targetPlayer = new Howl({
                    src: that.targetData.urls,
                    format: that.targetData.format,
                    html5: true,
                    onend: function() {
                        console.log('end error');
                        that.loadErrorsInARow++;
                        if (that.loadErrorsInARow > PLAYER_ERROR_RETRY_COUNT) {
                            that._cancelTransition();
                        } else {
                            window.setTimeout(function() {
                                console.log('end error retry...');
                                that.targetState = AS_LISTEN_CHANNEL;
                                that._restartCurrent();
                            }, PLAYER_ERROR_RETRY);
                        }
                    },
                    onloaderror: function() {
                        console.log('load error');
                        that.loadErrorsInARow++;
                        if (that.loadErrorsInARow > PLAYER_ERROR_RETRY_COUNT) {
                            that._cancelTransition();
                        } else {
                            window.setTimeout(function() {
                                console.log('load error retry...');
                                that._restartTarget();
                            }, PLAYER_ERROR_RETRY);
                        }
                    },
                });
                that.targetPlayer.volume(0.0);
                that.targetPlayer.once('load', function() {
                    that.loadErrorsInARow = 0;
                    console.log('target player: load');
                    if (that.currentPlayer) {
                        that.volume = that.currentPlayer.volume();
                        that.currentPlayer.fade(that.volume, 0.0, PLAYER_FADE_TIME);
                    }
                    that.targetPlayer.once('fade', function() {
                        console.log('target player: fade');
                        if (that.currentPlayer) {
                            that.currentPlayer.unload();
                        }
                        that.currentPlayer = that.targetPlayer;
                        that._setCurrentState(that.targetState);
                        that.currentData = that.targetData;

                        that.targetState = AS_NONE;
                        that.targetData = null;
                        that.targetPlayer = null;

                        that.isInTransition = false;
                        that._updatePlayerDisplay();
                    })
                    that.targetPlayer.fade(0.0, that.volume, PLAYER_FADE_TIME);
                });
                console.log('target player: play');
                that.targetPlayer.play();
                break;
        }
    }

}
global.SPA = SPA;
